import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Box,
  Card,
  useTheme,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
  Tooltip,
  TablePagination,
  Button,
  Typography,
  Divider,
  Grid,
  Container,
  CircularProgress
} from '@mui/material';
import UserContext from 'src/context/UserContext';
import { InventoryService } from 'src/services/InventoryService';
import SearchIcon from '@mui/icons-material/Search';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import EditProduct from 'src/components/EditProduct';
import Filter from 'src/content/applications/Users/common/filter';
import { useLocation, useSearchParams } from 'react-router-dom';
import AddProduct from 'src/components/AddProduct';
import ImportTemplate from '../ImportTemplate';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { ManagementServices } from 'src/services/ManagementServices';
import Swal from 'sweetalert2';
import { Role } from 'src/Role';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';


type ViewSingleClientProfileProps = {
  callBackPage: () => void;
  shopId: string;
};
const AdminInventory: React.FC<ViewSingleClientProfileProps> = ({ callBackPage, shopId }) => {

  const theme = useTheme();
  const [inventoryCount, setInventoryCount] = useState<number>(0);
  const [inventory, setInventory] = useState<any>([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedQuantity, setQuantity] = useState(null);
  const [selectedPrice, setPrice] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [selectedInventoryId, setInventoryId] = useState(null);
  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [value, setValue] = useState<any>('All');
  const [billedValue, setBilledValue] = useState<any>('None');
  const [open, setOpen] = useState(false);
  const [offsetIncrease, setOffsetIncrease] = useState(true);
  const [lazyLoadingOffset, seTlazyLoadingOffset] = useState(0);
  const [ifSearchChanged, setIfSearchChanged] = useState(false);
  const initialState = { data: '' };
  const [searchData, setSearchData] = useState<any>(initialState);
  const [openAddProduct, setOpenAddProduct] = useState<boolean>(false);
  const location = useLocation();
  const [userId, setUserId] = useState<string | null>(null);
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');
  const [currentDate, setCurrentDate] = useState(new Date());
  const data = {
    searchTag: currentSearchTerm,
    startDate: startValue,
    endDate: endValue,
    billedStatus: billedValue
  };
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedShopId, setSelectedShopId] = useState();
  const [openTemplate, setOpenTemplate] = useState(false);
  const [user] = useContext(UserContext);
  const role = user && user?.role;
  const [searchParams] = useSearchParams();
  
  const [loading, setLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    
    const params = new URLSearchParams(location.search);
    const userIdFromUrl = params.get('userId');
    setUserId(userIdFromUrl);
    if(shopId){
      getAllInventorySearch();
    }
    setCurrentDate(new Date());
  }, [
    shopId,
    page,
    rowsPerPage,
    currentSearchTerm,
    billedValue,
    startValue,
    endValue
  ]);

  const getAllInventorySearch = async () => {
    let inventoryOffset = lazyLoadingOffset;

    if (!ifSearchChanged) {
      inventoryOffset = offsetIncrease
        ? lazyLoadingOffset + 1
        : lazyLoadingOffset - 1;
    }
    try {
      const res = await InventoryService.getAllInventoriesBySearch(shopId, rowsPerPage, data, inventoryOffset);
      if (res.success) {
        seTlazyLoadingOffset(inventoryOffset);
       
        if(ifSearchChanged ){
          const inventoryList = res.data[0]?.inventoryList || [];
          setInventory(inventoryList);
        }
        else{
          const inventoryList = res.data[0]?.inventoryList || [];
          setInventory(prevList => [...prevList, ...inventoryList]);
        }
        setIsLoading(false)
        setIfSearchChanged(false);
        
        const count = res.data[0]?.count || 0;
        setInventoryCount(Number(count));
      } else {
       
        console.error('Failed to fetch inventories:', res.message);
      }
    } catch (error) {
      console.error('Error fetching inventories:', error);
    } finally {
      setLoading(false);
  }
  };
  const handleEditOpen = (index, product, quantity, price, inventoryId) => {
   
      setSelectedProduct(product);
      setQuantity(quantity);
      setPrice(price);
      setInventoryId(inventoryId);
      setOpenEdit(true);
      setEditIndex(index);
    
  };

  const handleEditClose = () => {
    
      setOpenEdit(false);
    
  };

  const handlePageChange = (event, newPage) => {
   
      const increaseOffset = page > newPage ? false : true;
      setOffsetIncrease(increaseOffset);
      setIsLoading(true)
      setPage(newPage);
    
  };

  const handleRowsPerPageChange = (event) => {
   
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      seTlazyLoadingOffset(0);
    
  };

  const handleChange = (event: any) => {
   
      const selectedvalue = event.target.value;
      const today = new Date();
      setValue(selectedvalue);
      setOpen(false);
      setIfSearchChanged(true);
      setPage(0);
      seTlazyLoadingOffset(1);
      switch (selectedvalue) {
        case 'Today':
          setStartValue(new Date());
          setEndValue(new Date());
          setShowCustomDatePicker(false);
          break;
        case 'Last 7 days':
          const sevenDaysAgo = new Date(today);
          sevenDaysAgo.setDate(today.getDate() - 6);
          setEndValue(sevenDaysAgo);
          setStartValue(today);
          setShowCustomDatePicker(false);
          break;
        case 'Last 28 days':
          const twentyEightDaysAgo = new Date(today);
          twentyEightDaysAgo.setDate(today.getDate() - 27);
          setEndValue(twentyEightDaysAgo);
          setStartValue(today);
          setShowCustomDatePicker(false);
          break;
        case 'Custom':
          setStartValue(null);
          setEndValue(null);
          setShowCustomDatePicker(true);
          break;
        default:
          setStartValue(null);
          setEndValue(null);
          setShowCustomDatePicker(false);
          break;
      }
    
  };

  const handelBilledAmout = (value: any) => {
 
      setBilledValue(value);
      setIfSearchChanged(true);
      setPage(0);
      seTlazyLoadingOffset(1);
    
  };

  const handleOpen = () => {
      setOpen(true);  
  };

  const handleSearchChange = () => {
    
      setOpen(false);
      setIfSearchChanged(true);
      setPage(0);
      seTlazyLoadingOffset(1);
    
  };

  const updateInventoryList = (index, updatedQuantity, updatedPrice) => {
    
      const updatedList = [...inventory];
      updatedList[index] = {
        ...updatedList[index],
        quantity: updatedQuantity,
        price: updatedPrice
      };
      setInventory(updatedList);
    
  };

  const openCloseAddProduct = () => {
   
      setOpenAddProduct(!openAddProduct);
    
  };

  const handleTemplateOpen = (shopID: any) => {
    
      setSelectedShopId(shopID);
      setOpenTemplate(true);
    
  };

  const handleTemplateClose = () => {
    
      setOpenTemplate(false);
    
  };

  const deleteConform = (inventoryId: any,index:any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to remove this inventory item!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Close'
    }).then((result) => {
      if (result.isConfirmed) {
        ManagementServices.deleteInventory(inventoryId)
          .then((res) => {
          if (res.success) {
            const updatedInvenotoryList = [...inventory];
            updatedInvenotoryList.splice(index, 1);
            setInventory(updatedInvenotoryList);
            setInventoryCount(inventoryCount - 1);
            Swal.fire('Inventory Item Removed', 'Successfully', 'success');
          }
          // Swal.fire('Deleted!', 'Invoice has been deleted.', 'success').then(
          //   (result) => {
          //     if (result.isConfirmed ) {
          //       window.location.reload();
          //     }
          //   }
          // );
        })
        .catch((error) => {
          Swal.fire(
            'Error!',
            'There was an error deleting your file.',
            'error'
          );
        });
      }
    });
  };
  const handleSearch = () => {
    setCurrentSearchTerm(searchKeyword)
    setPage(0);
    seTlazyLoadingOffset(1);
    setIfSearchChanged(true);
  };

  const handleSearchClear = () => {
    setIfSearchChanged(true)
    seTlazyLoadingOffset(1);
    setSearchKeyword('');
    setCurrentSearchTerm('')
    setPage(0);
  };

  const back = () => {
    callBackPage()
  };


  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12} md={12}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ArrowBackIcon />}
            onClick={() => back()}
            sx={{
              borderColor: 'primary.main',
              color: 'primary.main',
              '&:hover': {
                borderColor: 'primary.dark',
                color: 'primary.dark'
              },
              mb: 3
            }}
          >
            Back
          </Button>
          <Card>
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{ flexWrap: 'wrap' }}
                alignItems="center"
                gap={1}
              >
                <Typography
                  variant="h4"
                  component="h2"
                  m={2}
                  sx={{
                    fontSize: '2rem',
                    fontWeight: 'bold'
                  }}
                >
                  Inventory Details
                </Typography>
                <Box
                  display="flex"
                  sx={{ flexWrap: 'wrap', ml: 2, mr: 2 }}
                  alignItems="center"
                  gap={1}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleTemplateOpen(shopId);
                    }}
                  >
                    Import
                  </Button>
                  <Button variant="outlined" onClick={openCloseAddProduct}>
                    Add New Product
                  </Button>
                  <Filter
                    billedValue={billedValue}
                    handelBilledAmout={handelBilledAmout}
                    value={value}
                    startValue={startValue}
                    endValue={endValue}
                    showCustomDatePicker={showCustomDatePicker}
                    handleChange={handleChange}
                    handleOpen={handleOpen}
                    setStartValue={setStartValue}
                    setEndValue={setEndValue}
                    handleSearchChange={handleSearchChange}
                    dropDownHeading="Item Price"
                    open={open}
                    maxDate={currentDate}
                  />
                  <TextField
                    variant="outlined"
                    placeholder="Search Product"
                    value={searchKeyword}
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleSearch()}
                            color="primary"
                          >
                            <SearchIcon />
                          </IconButton>
                          <IconButton onClick={handleSearchClear} color="error">
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              </Box>
            </>
            <Divider />
            <div style={{ height: 20 }}></div>
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="300px"
              >
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>No</TableCell>
                      <TableCell>Product Name</TableCell>
                      <TableCell>SKU Number</TableCell>
                      <TableCell>Brand Name</TableCell>
                      <TableCell>Selling Price</TableCell>
                      <TableCell>Product Price</TableCell>
                      <TableCell>Available Quantity</TableCell>
                      <TableCell>Sold Quantity</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inventory.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          <Typography
                            variant="h4"
                            component="h2"
                            sx={{
                              fontSize: '1rem',
                              fontWeight: 'bold'
                            }}
                          >
                            No data to display
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      inventory.map((inventory: any, index) => (
                        <TableRow hover key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{inventory?.productName}</TableCell>
                          <TableCell>{inventory?.sKUNumber}</TableCell>
                          <TableCell>{inventory?.brandName}</TableCell>
                          <TableCell>{inventory?.price}</TableCell>
                          <TableCell>{inventory?.productPrice}</TableCell>
                          <TableCell>{inventory?.quantity}</TableCell>
                          <TableCell>
                            {inventory?.noOfSales ? inventory?.noOfSales : 0}
                          </TableCell>
                          <TableCell>
                            {new Date(inventory.createdAt).toLocaleDateString()}
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title="Edit shop details" arrow>
                              <IconButton
                                onClick={() =>
                                  handleEditOpen(
                                    index,
                                    inventory.productName,
                                    inventory.quantity,
                                    inventory.price,
                                    inventory._id
                                  )
                                }
                                sx={{
                                  '&:hover': {
                                    background: theme.colors.primary.lighter
                                  },
                                  color: theme.palette.primary.main
                                }}
                                color="inherit"
                                size="small"
                              >
                                <EditTwoToneIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete product" arrow>
                              <IconButton
                                onClick={() =>
                                  deleteConform(inventory._id, index)
                                }
                                sx={{
                                  '&:hover': {
                                    background: theme.colors.primary.lighter
                                  },
                                  color: theme.palette.primary.main
                                }}
                                color="inherit"
                                size="small"
                              >
                                <DeleteTwoToneIcon
                                  fontSize="small"
                                  color="error"
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <Box p={2} display="flex" justifyContent="center">
              {inventoryCount > inventory.length && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => handlePageChange(e, page + 1)}
                >
                  {isLoading ? 'Loading...' : 'See More'}
                </Button>
              )}
              {/* <TablePagination
            component="div"
            count={inventoryCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 20, 30, 40]}
          /> */}
            </Box>
          </Card>
        </Grid>
      </Grid>

      <EditProduct
        open={openEdit}
        onClose={handleEditClose}
        productName={selectedProduct}
        quantity={selectedQuantity}
        price={selectedPrice}
        shopId={shopId}
        inventoryId={selectedInventoryId}
        setInventory={setInventory}
        inventory={inventory}
        index={editIndex}
        // updateInventoryList={(updatedQuantity, updatedPrice) =>
        //   updateInventoryList(editIndex, updatedQuantity, updatedPrice)
        // }
        // get={getAllInventorySearch}
      />
      <AddProduct
        open={openAddProduct}
        onClose={openCloseAddProduct}
        shopId={shopId}
        userId={userId}
        setInventory={setInventory}
        inventory={inventory}
      />
      <ImportTemplate
        open={openTemplate}
        onClose={handleTemplateClose}
        shopId={selectedShopId}
        get={getAllInventorySearch}
      />
    </>
  );
}

export default AdminInventory;
