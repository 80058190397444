import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Box,
  Card,
  Divider,
  useTheme,
  styled,
  TextField,
  IconButton,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  Tooltip,
  InputAdornment,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Button
} from '@mui/material';
import CopyAllTwoTone from '@mui/icons-material/CopyAllTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { ToastContainer, toast } from 'react-toastify';
import UserContext from 'src/context/UserContext';
import SearchIcon from '@mui/icons-material/Search';
import { ManagementServices } from 'src/services/ManagementServices';
import PreviewTwoToneIcon from '@mui/icons-material/PreviewTwoTone';
import Filter from 'src/content/applications/Users/common/filter';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import CustomerInvoiceEdit from '../../Shops/AdminInvoice/CustomerInvoiceEdit';
import ReceiptIcon from '@mui/icons-material/Receipt';
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function InvoiceDetails({ shopId, currency }) {
  const theme = useTheme();
  const [invoiceCount, setInvoiceCount] = useState<any>([]);
  const [invoice, setInvoice] = useState<any>([]);
  const [page, setPage] = useState<number>(0);
  const [openPreview, setOpenPreview] = useState(false);
  const [invoiceItemList, setInvoiceItem] = useState<any>([]);
  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [value, setValue] = useState<any>('All');
  const [billedValue, setBilledValue] = useState<any>('None');
  const [open, setOpen] = useState(false);
  const [offsetIncrease, setOffsetIncrease] = useState(true);
  const [lazyLoadingOffset, seTlazyLoadingOffset] = useState(0);
  const [ifSearchChanged, setIfSearchChanged] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');
  const [openEditInvoice, setOpenEditInvoice] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState();
  const [editIndex, setEditIndex] = useState(null);
  const data = {
    searchTag: currentSearchTerm,
    startDate: startValue,
    endDate: endValue,
    billedStatus: billedValue
  };
  const [searchKeyword, setSearchKeyword] = useState('');
  const [user] = useContext(UserContext);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    if (shopId) {
      getAllInvoicesBySearch();
    }
    setCurrentDate(new Date());
  }, [
    shopId,
    page,
    rowsPerPage,
    currentSearchTerm,
    billedValue,
    startValue,
    endValue
  ]);

  const getAllInvoicesBySearch = async () => {
    let inventoryOffset = lazyLoadingOffset;

    if (!ifSearchChanged) {
      inventoryOffset = offsetIncrease
        ? lazyLoadingOffset + 1
        : lazyLoadingOffset - 1;
    }
    try {
      const res = await ManagementServices.getAllInvoicesBySearch(
        shopId,
        rowsPerPage,
        data,
        inventoryOffset
      );
      if (res.success) {
        if (ifSearchChanged) {
          const invoiceList = res.data[0]?.invoiceList || [];
          setInvoice(invoiceList);
        } else {
          const invoiceList = res.data[0]?.invoiceList || [];
          setInvoice((prevList) => [...prevList, ...invoiceList]);
        }
        seTlazyLoadingOffset(inventoryOffset);
        setIfSearchChanged(false);
        setLoading(false);
        const count = res.data[0]?.count || 0;
        setInvoiceCount(count);
      } else {
        console.error('Failed to fetch invoices:', res.message);
      }
    } catch (error) {
      console.error('Error fetching invoices:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllInvoiceItems = (invoice: any) => {
    ManagementServices.getAllInvoiceItems(invoice._id).then((res) => {
      if (res.success) {
        console.log(res.data);
        const invoiceItems = res.data.invoiceItemList || [];

        const updatedItems = invoiceItems.map((item: any) => ({
          ...item,
          customerName: invoice.customerName,
          customerPhoneNo: invoice.customerPhoneNumber,
          shopName: invoice.shopName,
          shopPhoneNumber: invoice.phoneNumber,
          shopOwnerName: invoice.ownerName
        }));
        setInvoiceItem(updatedItems);
        setOpenPreview(true);
      } else {
      }
    });
  };

  const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
      width: '50vw',
      maxWidth: 'none'
    }
  }));

  const handlePreviewOpen = (invoice: any) => {
    getAllInvoiceItems(invoice);
  };

  const handlePreviewClose = () => {
    setOpenPreview(false);
  };

  const handlePageChange = (event, newPage) => {
    const increaseOffset = page > newPage ? false : true;
    setOffsetIncrease(increaseOffset);
    setLoading(true);
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    seTlazyLoadingOffset(0);
  };

  const handleChange = (event: any) => {
    const selectedvalue = event.target.value;
    const today = new Date();
    setValue(selectedvalue);
    setOpen(false);
    setIfSearchChanged(true);
    setPage(0);
    seTlazyLoadingOffset(1);
    switch (selectedvalue) {
      case 'Today':
        setStartValue(new Date());
        setEndValue(new Date());
        setShowCustomDatePicker(false);

        break;
      case 'Last 7 days':
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 6);
        setEndValue(sevenDaysAgo);
        setStartValue(today);
        setShowCustomDatePicker(false);

        break;
      case 'Last 28 days':
        const twentyEightDaysAgo = new Date(today);
        twentyEightDaysAgo.setDate(today.getDate() - 27);
        setEndValue(twentyEightDaysAgo);
        setStartValue(today);
        setShowCustomDatePicker(false);

        break;
      case 'Custom':
        setStartValue(null);
        setEndValue(null);
        setShowCustomDatePicker(true);

        break;
      default:
        setStartValue(null);
        setEndValue(null);
        setShowCustomDatePicker(false);

        break;
    }
  };

  const handelBilledAmout = (value: any) => {
    setBilledValue(value);
    setIfSearchChanged(true);
    setPage(0);
    seTlazyLoadingOffset(1);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSearchChange = () => {
    setOpen(false);
    setIfSearchChanged(true);
    setPage(0);
    seTlazyLoadingOffset(1);
  };
  const handleCopy = async (categoryId) => {
    await navigator.clipboard.writeText(categoryId);
    toast('Copied to clipboard');
  };
  const handleSearch = () => {
    setCurrentSearchTerm(searchKeyword);
    setPage(0);
    seTlazyLoadingOffset(1);
    setIfSearchChanged(true);
  };

  const handleSearchClear = () => {
    setIfSearchChanged(true);
    seTlazyLoadingOffset(1);
    setSearchKeyword('');
    setCurrentSearchTerm('');
    setPage(0);
  };

  const openCloseEditInvoice = (invoiceId: any, index: any) => {
    getAllInvoicesBySearch();
    setOpenEditInvoice(!openEditInvoice);
    setEditIndex(index)
    setSelectedInvoiceId(invoiceId);
  }
  
  const viewInvoice  = (invoiceId) => {
    const url = `https://fanbudget.com/public-invoice-view/${invoiceId}`;
    window.open(url, '_blank');
  };
  

  return (
    <>
      <StyledDialog open={openPreview} onClose={handlePreviewClose}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handlePreviewClose}
        >
          <Typography variant="h4" fontWeight="bold">
            Preview Invoice Details
          </Typography>
        </BootstrapDialogTitle>
        <List sx={{ marginTop: '-40px', marginLeft: '20px' }}>
          <ListItem alignItems="flex-start">
            <ListItemText
              secondary={
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Shop Name :
                  </Typography>{' '}
                  {invoiceItemList[0]?.shopName}
                </>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText
              secondary={
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Shop Owner Name :
                  </Typography>{' '}
                  {invoiceItemList[0]?.shopOwnerName}
                </>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText
              secondary={
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Shop Contact Number :
                  </Typography>{' '}
                  {invoiceItemList[0]?.shopPhoneNumber}
                </>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText
              secondary={
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Customer Name :
                  </Typography>{' '}
                  {invoiceItemList[0]?.customerName != null
                    ? invoiceItemList[0]?.customerName
                    : '-'}
                </>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText
              secondary={
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Customer Contact Number :
                  </Typography>{' '}
                  {invoiceItemList[0]?.customerPhoneNo != null
                    ? invoiceItemList[0]?.customerPhoneNo
                    : '-'}
                </>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </List>

        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Inventory Item</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(invoiceItemList) &&
                  invoiceItemList.map((item, index) => (
                    <TableRow hover key={index}>
                      <TableCell>{item?.productName}</TableCell>
                      <TableCell>
                        {currency} {item?.price}
                      </TableCell>
                      <TableCell>{item?.quantity}</TableCell>
                      <TableCell>
                        {currency} {item?.amount}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </StyledDialog>

      <Card>
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ flexWrap: 'wrap' }}
            alignItems="center"
            gap={1}
          >
            <Typography
              variant="h4"
              component="h2"
              m={2}
              sx={{
                fontSize: '2rem',
                fontWeight: 'bold'
              }}
            >
              Invoice Details
            </Typography>
            <Box
              display="flex"
              sx={{ flexWrap: 'wrap', ml: 2, mr: 2 }}
              alignItems="center"
              gap={1}
            >
              <Filter
                billedValue={billedValue}
                handelBilledAmout={handelBilledAmout}
                value={value}
                startValue={startValue}
                endValue={endValue}
                showCustomDatePicker={showCustomDatePicker}
                handleChange={handleChange}
                handleOpen={handleOpen}
                setStartValue={setStartValue}
                setEndValue={setEndValue}
                handleSearchChange={handleSearchChange}
                dropDownHeading="Billed Amount"
                open={open}
                maxDate={currentDate}
              />

              <TextField
                variant="outlined"
                placeholder="Search Invoices"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleSearch()}
                        color="primary"
                      >
                        <SearchIcon />
                      </IconButton>
                      <IconButton onClick={handleSearchClear} color="error">
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </Box>
        </>

        <Divider />
        <div style={{ height: 20 }}></div>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="300px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Invoice Number</TableCell>
                  <TableCell>Total value</TableCell>
                  <TableCell>Discount</TableCell>
                  <TableCell>Sub Total</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoice.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <Typography
                        variant="h4"
                        component="h2"
                        sx={{
                          fontSize: '1rem',
                          fontWeight: 'bold'
                        }}
                      >
                        No data to display
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  invoice?.map((invoice: any, index) => {
                    return (
                      <TableRow hover key={index}>
                        <TableCell>
                          <Tooltip title="Copy ID" arrow>
                            <IconButton
                              onClick={() => handleCopy(invoice._id)}
                              sx={{
                                '&:hover': {
                                  background: theme.colors.primary.lighter
                                },
                                color: theme.palette.warning.main
                              }}
                              color="inherit"
                              size="small"
                            >
                              <CopyAllTwoTone fontSize="small" />
                              <ToastContainer
                                position="bottom-right"
                                autoClose={1000}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick={false}
                                rtl={false}
                                pauseOnFocusLoss={false}
                                draggable={false}
                                pauseOnHover={false}
                                theme="dark"
                              />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {currency} {invoice.total}
                        </TableCell>
                        <TableCell>
                          {currency} {invoice.discount}
                        </TableCell>
                        <TableCell>
                          {currency} {invoice.subTotal}
                        </TableCell>
                        <TableCell>
                          {moment(invoice.createdAt).format(
                            'MMM Do YYYY, h:mm A'
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Preview Invoice" arrow>
                            <IconButton
                              onClick={() => viewInvoice(invoice._id)}
                              sx={{
                                '&:hover': {
                                  background: theme.colors.warning.lighter
                                },
                                color: theme.palette.warning.main
                              }}
                              color="warning"
                              size="small"
                            >
                              <ReceiptIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Edit Invoice" arrow>
                            <IconButton
                              onClick={() =>
                                openCloseEditInvoice(invoice._id, index)
                              }
                              sx={{
                                '&:hover': {
                                  background: theme.colors.primary.lighter
                                },
                                color: theme.palette.primary.main
                              }}
                              color="inherit"
                              size="small"
                            >
                              <EditTwoToneIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Preview Invoice items" arrow>
                            <IconButton
                              onClick={() => handlePreviewOpen(invoice)}
                              sx={{
                                '&:hover': {
                                  background: theme.colors.primary.lighter
                                },
                                color: theme.palette.primary.main
                              }}
                              color="inherit"
                              size="small"
                            >
                              <PreviewTwoToneIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Box p={2} display="flex" justifyContent="center">
          {invoiceCount > invoice.length && (
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handlePageChange(e, page + 1)}
            >
              {loading ? 'Loading...' : 'See More'}
            </Button>
          )}
        </Box>

        <CustomerInvoiceEdit
          open={openEditInvoice}
          onClose={openCloseEditInvoice}
          invoiceId={selectedInvoiceId}
          setInvoiceList={setInvoice}
          invoiceList={invoice}
          index={editIndex}
        />
      </Card>
    </>
  );
}

export default InvoiceDetails;
