import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import {
  TemplateCategoryDetails,
  activeStatus
} from 'src/models/TemplateCategory';
import CloseIcon from '@mui/icons-material/Close';
import { SketchPicker } from 'react-color';
import moment from 'moment';
import { InventoryService } from 'src/services/InventoryService';
import { CopyAllTwoTone, Inventory } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TemplateInventoryDetails, TemplateInventoryProductsDetails } from 'src/models/InventoryDetails';
import exportFromJSON from 'export-from-json';
import SearchIcon from '@mui/icons-material/Search';
import PreviewIcon from '@mui/icons-material/Preview';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import Papa from 'papaparse';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface Filters {
  search: string;
  status?: any;
}


const applyFilters = (
  TemplateCategories: TemplateInventoryProductsDetails[],
  filters: Filters
): TemplateInventoryProductsDetails[] => {
  return TemplateCategories.filter((category) => {
    let matches = true;

    if (filters.search) {
      const keyword = filters.search.toLowerCase();
      if (!category.productName?.toLowerCase().includes(keyword)) {
        matches = false;
      }
    }
    return matches;
  });
};



const applyPagination = (
  allCategories: TemplateInventoryDetails[],
  page: number,
  limit: number
): any[] => {
  return allCategories.slice(page * limit, page * limit + limit);
};

interface Props {
  TemplateInventory: TemplateInventoryProductsDetails[];
  setTemplateInventory: React.Dispatch<
    React.SetStateAction<TemplateInventoryProductsDetails[]>
  >;
}

const InventoryTable: FC<Props> = ({
  TemplateInventory,
  setTemplateInventory
}) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filters, setFilters] = useState<Filters>({ search: null});
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const [open, setOpen] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);

  const [inventoryId, setId] = useState();

  const [TemplateName, setTemplateName] = useState();
  const [SKUNumber, setSKUNumber] = useState();
  const [ProductName, setProductName] = useState();
  const [Price, setPrice] = useState();
  const [Quantity, setQuantity] = useState();
  const [BrandNamer, setBrandNamer] = useState();

  const [openPreview, setOpenPreview] = useState(false);
const [previewInventory, setPreviewInventory] = useState(null)

const [openImport, setopenImport] = useState(false);//CSV Dialog
const [importCSV, setimportCSV] = useState(null)
const [csvFile, setCsvFile] = useState(null); //Import Csv





  const LIMIT_P_C = 10;
  const [isOffset_p_c, setOffset_p_c] = useState<number>(0);
  const [seeMore_p_c, setSeeMore_p_c] = useState(false);

  useEffect(() => {
    getDefaultInventory();
  }, []);


  const getDefaultInventory = () => {
    InventoryService.getDefaultInventory().then(
      (res) => {
        if (res.success) {
          setTemplateInventory(res.data);
          
        } else {
          setSeeMore_p_c(false);
        }
      }
    );
  };

  const handleClickOpen = (_id, templateName, sKUNumber, productName, price, quantity, brandName) => {
    setId(_id);
    setTemplateName(templateName);
    setSKUNumber(sKUNumber);
    setProductName(productName);
    setPrice(price);
    setQuantity(quantity);
    setBrandNamer(brandName);
    setOpen(true);
  };


  const updateInventory = (value) => {
    setOpen(false);
    const id=inventoryId;
    const data = {
      templateName: value.TemplateName,
      sKUNumber:value.SKUNumber,
      productName:value.ProductName,
      price: value.Price,
      quantity:value.Quantity,
      brandName: value.BrandNamer
    };

    InventoryService.updateInventory(data, id).then((res) => {
      if (res.success) {
       
        Swal.fire('Category Updated');
      } else {
        Swal.fire({
          icon: 'error',
          title: res.error,
          confirmButtonColor: '#FD7F00'
        });
      }
    });
  };

  const deleteInventory = (id : any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        InventoryService.deleteInventory(id).then((res) => {
          if (res.success) {
            Swal.fire('Group Removed');
          } else {
            Swal.fire({
              icon: 'error',
              title: res.error,
              confirmButtonColor: '#FD7F00'
            });
          }
        });
      }
    });
  };

  const handleSearch = (): void => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: searchKeyword
    }));
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchKeyword(event.target.value);
  };

  const filteredAllInventory = applyFilters(TemplateInventory, filters);
  const paginatedAllCategories = applyPagination(
    filteredAllInventory,
    page,
    limit
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
    formikCreate.resetForm();
  };

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'ALL') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  
  


  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const validationSchema = yup.object({
    TemplateName: yup.string().typeError('Not a String'),
    SKUNumber: yup.string().typeError('Not a String')
  });

  const formik = useFormik({
    initialValues: {
      
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      updateInventory(values);
      formik.resetForm();
    }
  });

  
  const formikCreate = useFormik({
    initialValues: {
      templateNamei: '',
      sKUNumberi: '',
      productNamei: '',
      pricei: '',
      quantityi: '',
      brandNamei: ''
    },
    validationSchema: yup.object({
      templateNamei: yup.string().required('Template Name is required'),
      sKUNumberi: yup.string().required('SKU Number is required'),
      productNamei: yup.string().required('Product Name is required'),
      pricei: yup.number().required('Price is required').positive(),
      quantityi: yup.number().required('Quantity is required').positive(),
      brandNamei: yup.string().required('Brand Name is required')
    }),
    onSubmit: (values, { resetForm }) => {
      const data = {
        templateName: values.templateNamei,
        sKUNumber: values.sKUNumberi,
        productName: values.productNamei,
        price: values.pricei,
        quantity: values.quantityi,
        brandName: values.brandNamei
      };

      InventoryService.createInventory(data).then((res) => {
        if (res.success) {
          getDefaultInventory();
          setOpenCreate(false);
          Swal.fire('Created', 'Successfully', 'success');
          resetForm();
        } else {
          Swal.fire({
            icon: 'error',
            title: res.error,
            confirmButtonColor: '#FD7F00'
          });
        }
      });
    }
  });

 
  const theme = useTheme();

  const handleExport = () => {
    const data = TemplateInventory.map(inventory => ({
      "Inventory No": inventory._id,
      // "Inventory Name": inventory.templateName,
      "SKU Number": inventory.sKUNumber,
      "Product Name": inventory.productName,
      "Price": inventory.price,
      "Quantity": inventory.quantity,
      "Brand Name": inventory.brandName,
    }));
    const fileName = "inventory";
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
  }


  const handlePreviewOpen = (inventory) => {
    setPreviewInventory(inventory);
    setOpenPreview(true);
  };
  
  const handlePreviewClose = () => {
    setOpenPreview(false);
    setPreviewInventory(null);
  };


  //Import CSV task
  const handleImportOpen = (inventory) => {
    setimportCSV(inventory);
    setopenImport(true);
  };
  
  const handleImportClose = () => {
    setopenImport(false);
    setimportCSV(null);
  };



  const handleUploadCsv = () => {
    handleImportClose();
    if (!csvFile) {
      
      // Handle the case when no file is selected
      return;
    }
  
    Papa.parse(csvFile, {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: (results) => {
        const inventories = results.data.map((row) => ({
          templateName: row['Inventory Name'],
          sKUNumber: row['SKU Number'],
          productName: row['Product Name'],
          price: parseFloat(row['Price']),
          quantity: parseInt(row['Quantity']),
          brandName: row['Brand Name'],
        }));
  
        // Create new inventories using the InventoryService
        InventoryService.createMultipleInventories(inventories)
          .then((res) => {
            if (res.success) {
              getDefaultInventory(); // Refresh the inventory list
              setCsvFile(null); // Reset the file input field
              Swal.fire('Inventories Created', 'Successfully', 'success');
            } else {
              Swal.fire({
                icon: 'error',
                title: res.error,
                confirmButtonColor: '#FD7F00',
              });
            }
          })
          .catch((err) => {
            console.error('Error creating inventories:', err);
            Swal.fire({
              icon: 'error',
              title: 'An error occurred while creating inventories',
              confirmButtonColor: '#FD7F00',
            });
          });
      },
    });
  };

  
  


  
  return (
    <>

    {/* Dialog box for Update Inventory */}
      <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Update Inventory
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                required
                id="TemplateName"
                label="Inventory Name"
                defaultValue={TemplateName}
                variant="filled"
                onChange={formik.handleChange}
              />
              <div style={{ height: 20 }}></div>
              <TextField
                required
                id="SKUNumber"
                label="SKU Number"
                defaultValue={SKUNumber}
                variant="filled"
                onChange={formik.handleChange}
              />
              <div style={{ height: 20 }}></div>
              <TextField
                required
                id="ProductName"
                label="Product Name"
                defaultValue={ProductName}
                variant="filled"
                onChange={formik.handleChange}
              />
              <div style={{ height: 20 }}></div>
              <TextField
                required
                id="Price"
                label="Price"
                defaultValue={Price}
                variant="filled"
                onChange={formik.handleChange}
              />
              <div style={{ height: 20 }}></div>
              <TextField
                required
                id="Quantity"
                label="Quantity"
                defaultValue={Quantity}
                variant="filled"
                onChange={formik.handleChange}
              />
              <div style={{ height: 20 }}></div>
              <TextField
                required
                id="BrandNamer"
                label="Brand Name"
                defaultValue={BrandNamer}
                variant="filled"
                onChange={formik.handleChange}
              />
              <div style={{ height: 20 }}></div>
              <Button color="primary" variant="contained" type="submit">
                Update
              </Button>
            </form>
          </DialogContent>
        </BootstrapDialog>
        <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
       
      </Box>


        {/* Dialog box for add ionventory */}

        <Dialog
        onClose={handleCloseCreate}
        aria-labelledby="customized-dialog-title"
        open={openCreate}
        >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseCreate}>
          Create Inventory Details
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form onSubmit={formikCreate.handleSubmit}>
          <div style={{ gap: '1rem', width:'100%' }}>
            <TextField
              fullWidth
              id="templateNamei"
              name="templateNamei"
              label="Template Name"
              variant="outlined"
              value={formikCreate.values.templateNamei}
              onChange={formikCreate.handleChange}
              error={
                formikCreate.touched.templateNamei &&
                Boolean(formikCreate.errors.templateNamei)
              }
              helperText={
                formikCreate.touched.templateNamei &&
                formikCreate.errors.templateNamei
              }
            />
             <div style={{ height: 20 }}></div>
            <TextField
              fullWidth
              id="sKUNumberi"
              name="sKUNumberi"
              label="SKU Number"
              variant="outlined"
              value={formikCreate.values.sKUNumberi}
              onChange={formikCreate.handleChange}
              error={
                formikCreate.touched.sKUNumberi &&
                Boolean(formikCreate.errors.sKUNumberi)
              }
              helperText={
                formikCreate.touched.sKUNumberi && formikCreate.errors.sKUNumberi
              }
            />
             <div style={{ height: 20 }}></div>
            <TextField
              fullWidth
              id="productNamei"
              name="productNamei"
              label="Product Name"
              variant="outlined"
              value={formikCreate.values.productNamei}
              onChange={formikCreate.handleChange}
              error={
                formikCreate.touched.productNamei &&
                Boolean(formikCreate.errors.productNamei)
              }
              helperText={
                formikCreate.touched.productNamei &&
                formikCreate.errors.productNamei
              }
            />
             <div style={{ height: 20 }}></div>
            <TextField
              fullWidth
              id="pricei"
              name="pricei"
              label="Price"
              variant="outlined"
              value={formikCreate.values.pricei}
              onChange={formikCreate.handleChange}
              error={
                formikCreate.touched.pricei && Boolean(formikCreate.errors.pricei)
              }
              helperText={formikCreate.touched.pricei && formikCreate.errors.pricei}
            />
             <div style={{ height: 20 }}></div>
            <TextField
              fullWidth
              id="quantityi"
              name="quantityi"
              label="Quantity"
              variant="outlined"
              value={formikCreate.values.quantityi}
              onChange={formikCreate.handleChange}
              error={
                formikCreate.touched.quantityi &&
                Boolean(formikCreate.errors.quantityi)
              }
              helperText={
                formikCreate.touched.quantityi &&
                formikCreate.errors.quantityi
              }
            />
            <div style={{ height: 20 }}></div>
            <TextField
              fullWidth
              id="brandNamei"
              name="brandNamei"
              label="Brand Name"
              variant="outlined"
              value={formikCreate.values.brandNamei}
              onChange={formikCreate.handleChange}
              error={
                formikCreate.touched.brandNamei &&
                Boolean(formikCreate.errors.brandNamei)
              }
              helperText={
                formikCreate.touched.brandNamei &&
                formikCreate.errors.brandNamei
              }
            />
            </div>
            <Box mt={3}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
              >
                Create Inventory
              </Button>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseCreate} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
        <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
      </Box>
              
              {/* Dialog box for preview details */}
      
<Dialog open={openPreview} onClose={handlePreviewClose}>
  <DialogTitle>Preview Inventory Details</DialogTitle>
  <DialogContent>
    {previewInventory && (
      <>
      
      <div style={{ height: 20 }}></div>
              <TextField
              fullWidth
              id="brandNamei"
              name="brandNamei"
              label="Brand Name"
              variant="outlined"
              value={previewInventory.templateName}
            />
        <div style={{ height: 20 }}></div>
        <TextField
              fullWidth
              id="brandNamei"
              name="brandNamei"
              label="Brand Name"
              variant="outlined"
              value={previewInventory.sKUNumber}
            />
        <div style={{ height: 20 }}></div>
        <TextField
              fullWidth
              id="brandNamei"
              name="brandNamei"
              label="Brand Name"
              variant="outlined"
              value={previewInventory.productName}
            />
        <div style={{ height: 20 }}></div>
        <TextField
              fullWidth
              id="brandNamei"
              name="brandNamei"
              label="Brand Name"
              variant="outlined"
              value={previewInventory.price}
            />
        <div style={{ height: 20 }}></div>
        <TextField
              fullWidth
              id="brandNamei"
              name="brandNamei"
              label="Brand Name"
              variant="outlined"
              value={previewInventory.quantity}
            />
        <div style={{ height: 20 }}></div>
        <TextField
              fullWidth
              id="brandNamei"
              name="brandNamei"
              label="Brand Name"
              variant="outlined"
              value={previewInventory.brandName}
            />
      </>
    )}
  </DialogContent>
</Dialog>

{/* Input field for import csv */}


<Dialog open={openImport} onClose={handleImportClose}>
  <DialogTitle>Import CSV</DialogTitle>
  <DialogContent>
  <input
  type="file"
  accept=".csv"
  onChange={(e) => setCsvFile(e.target.files[0])}
/>
<Box mt={3}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={handleUploadCsv}
                
                fullWidth
              >
                Create Inventory
              </Button></Box>
  </DialogContent>
</Dialog>



      <Card>
      <CardHeader
    title="Inventory"
   action={
    <Box display="flex" alignItems="center" gap={1}>
          <TextField
            variant="outlined"
            placeholder="Search by Inventory Name"
            value={searchKeyword}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button onClick={handleSearch}>
                    <SearchIcon />
                  </Button>
                </InputAdornment>
              )
            }}
          />
          {/* Download CSV */}
          <Tooltip title="Download CSV" arrow>
          <IconButton
            onClick={handleExport}
            sx={{'&:hover': {background: theme.colors.primary.lighter},
                            color: theme.palette.primary.main}}
            color="inherit"
             size="small"
          >
              <DownloadIcon fontSize="medium" />
                  <ToastContainer
                      position="bottom-right"
                      autoClose={1000}
                      hideProgressBar
                      newestOnTop={false}
                      closeOnClick={false}
                      rtl={false}
                      pauseOnFocusLoss={false}
                      draggable={false}
                      pauseOnHover={false}
                      theme="dark"
                    />
           </IconButton>
           
           </Tooltip>
          
           {/* Import CSV */}
           <Tooltip title="Import CSV" arrow>
          <IconButton
            onClick={() => handleImportOpen(true)}

            sx={{'&:hover': {background: theme.colors.primary.lighter},
                            color: theme.palette.primary.main}}
            color="inherit"
             size="small"
          >
              <UploadIcon fontSize="medium" />
                  <ToastContainer
                      position="bottom-right"
                      autoClose={1000}
                      hideProgressBar
                      newestOnTop={false}
                      closeOnClick={false}
                      rtl={false}
                      pauseOnFocusLoss={false}
                      draggable={false}
                      pauseOnHover={false}
                      theme="dark"
                    />
           </IconButton>
           </Tooltip>

           {/* Create New Inventory */}
           <Tooltip title="Create New Inventory" arrow>
          <IconButton
            onClick={() => setOpenCreate(true)}
            sx={{'&:hover': {background: theme.colors.primary.lighter},
                            color: theme.palette.primary.main}}
            color="inherit"
             size="small"
          >
              <AddCircleIcon fontSize="medium" />
                  <ToastContainer
                      position="bottom-right"
                      autoClose={1000}
                      hideProgressBar
                      newestOnTop={false}
                      closeOnClick={false}
                      rtl={false}
                      pauseOnFocusLoss={false}
                      draggable={false}
                      pauseOnHover={false}
                      theme="dark"
                    />
           </IconButton>
           </Tooltip>
           
      </Box>     
   }
    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
  />
       
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Inventry No</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>SKU Number</TableCell>
                
                <TableCell>Price</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Brand Name</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedAllCategories.map((inventory, index) => {
                const rowIndex = (page * limit) + index + 1;
                return (
                  <TableRow hover key={inventory._id}>
                    
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {rowIndex}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                      {inventory.productName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                      {inventory.sKUNumber}
                      </Typography>
                    </TableCell>
                    
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                      {inventory.price}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                      {inventory.quantity}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                      {inventory.brandName}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Preview" arrow>
                        <IconButton
                           onClick={() => handlePreviewOpen(inventory)}

                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.warning.main
                          }}
                          color="inherit"
                          size="small"
                        >
                          <PreviewIcon fontSize="small" />
                          <ToastContainer
                            position="bottom-right"
                            autoClose={1000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick={false}
                            rtl={false}
                            pauseOnFocusLoss={false}
                            draggable={false}
                            pauseOnHover={false}
                            theme="dark"
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit category" arrow>
                        <IconButton
                          onClick={() =>
                            handleClickOpen(
                              inventory._id,
                              inventory.templateName,
                              inventory.sKUNumber,
                              inventory.productName,
                              inventory.price,
                              inventory.quantity,
                              inventory.brandName
                            )
                          }
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                          }}
                          color="inherit"
                          size="small"
                        >
                          <EditTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Category" arrow>
                        <IconButton
                          onClick={() => deleteInventory(inventory._id)}
                          sx={{
                            '&:hover': {
                              background: theme.colors.error.lighter
                            },
                            color: theme.palette.error.main
                          }}
                          color="inherit"
                          size="small"
                        >
                          <DeleteTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component="div"
            count={filteredAllInventory.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 30]}
          />
        </Box>
      </Card>
    </>
  );
};

export default InventoryTable;
